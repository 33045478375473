<script>
import { mapState } from "vuex"
import SearchTopBar from "@/components/global/SearchTopBar"
import PersonFacilityPicker from "@/components/global/PersonFacilityPicker"
import SitePickerStatic from "@/components/global/SitePickerStatic"
import InstrumentPickerStatic from "@/components/global/InstrumentPickerStatic"
import InstrumentList from "@/components/instrument/InstrumentList.vue"
import ColorCodeLegend from "@/components/instrument/ColorCodeLegend.vue"
import Toggles from "@/components/instrument/Toggles.vue"
export default {
  components: {
    SitePickerStatic,
    PersonFacilityPicker,
    InstrumentPickerStatic,
    InstrumentList,
    ColorCodeLegend,
    Toggles,
  },
  data() {
    return {
      componentKey: 0,
      masterStartDate: null,
      masterEndDate: null,
      displayStartDate: null,
      displayEndDate: null,
      submittedStartDate: null,
      submittedEndDate: null,
      site: false,
      armsites: {
        id: "armsites",
        label: "Sites",
        defaultLabel: "All Sites",
        placeholder: "All Sites",
        multiple: true,
        value: [],
        options: [],
      },
      facilities: {
        id: "currentFacility",
        defaultLabel: "All Facilites",
        placeholder: "All Facilities",
        siteCode: "",
        readOnly: false,
        value: "",
        options: [],
      },
      instruments: {
        id: "instruments",
        label: "Instruments",
        placeholder: "All Instruments",
        multiple: false,
        value: "",
        options: [],
        retrieveAllOnCreate: false,
      },
      selectedSite: null,
      selectedFacility: null,
      personInstruments: [],
      quickFiltered: [],
    }
  },
  computed: {
    ...mapState("instrument", [
      "personAllInstruments",
    ]),
    showSites() {
      return this.armsites.options.length > 0
    },
    showFacilities() {
      return this.facilities.options.length > 0
    },
    filteredPersonInstruments() {
      let filtered = this.personAllInstruments
      //including this part to deal with tests
      if (!this.personAllInstruments || !this.personAllInstruments.length) {
        return []
      }
      if (this.quickFiltered.length != 0) {
        filtered = this.personAllInstruments.filter(itm => {
          return !this.quickFiltered.includes(itm)
        })
      }
      if (this.facilities.siteCode != "") {
        filtered = filtered.filter(itm => {
          return this.facilities.siteCode == itm.siteCode
        })
      }
      if (this.instruments.facilityCode != "") {
        filtered = filtered.filter(itm => {
          return this.instruments.facilityCode == itm.facilityCode
        })
      }
      if (this.instruments.value != "") {
        console.log(this.instruments.value)
        filtered = filtered.filter(itm => {
          return this.instruments.value == itm.instrumentClassCode
        })
      }
      return filtered
    },
  },
  watch: {
    filteredPersonInstruments(newInstruments) {
      this.$nextTick(() => {
        this.$refs.instrumentList.filteredPersonInstruments = newInstruments
      })
    },
  },
  created() {
    this.getPersonInstruments()
    this.resetMasterDates()
  },
  methods: {
    resetMasterDates() {
      this.masterStartDate = null
      this.masterEndDate = null
      //solve issue of dates/zoom level persisting after navigating away
      this.$store.dispatch("instrument/setMasterStartDate", null)
      this.$store.dispatch("instrument/setMasterEndDate", null)
    },
    updateStartDate(date) {
      this.$store.dispatch("setMasterStartDate", date)
    },
    updateEndDate(date) {
      this.$store.dispatch("setMasterEndDate", date)
    },
    handleSubmitDates(event) {
      event.preventDefault()
      const formattedStartDate = this.formatDate(this.masterStartDate)
      const formattedEndDate = this.formatDate(this.masterEndDate)
      this.$store.dispatch("instrument/setMasterStartDate", formattedStartDate)
      this.$store.dispatch("instrument/setMasterEndDate", formattedEndDate)
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split("-")
      return `${month}-${day}-${year}`
    },
    handleToggle(groupName) {
      if (groupName === "DQR") {
        this.$store.dispatch("instrument/toggleDQR")
      } else if (groupName === "DQA") {
        this.$store.dispatch("instrument/toggleDQA")
      }
    },
    getPersonInstruments() {
      this.$store.dispatch("instrument/retrieveInstrumentsByPersonId").then((data) => {
        this.instruments = {
          ...this.instruments,
          options: data,
        }
        this.createSiteOptions()
      })
    },
    createSiteOptions() {
      const siteCodes = this.$store.getters["instrument/personInstruments"].map( instr => instr.siteCode)
      const siteSet = new Set(siteCodes)
      let siteOptions = []
      this.$store.dispatch("armint/retrieveAllSites").then((data) => {
        siteOptions = data.reduce((resultArr, itm ) => {
          if (siteSet.has(itm.siteCode)) {
            resultArr.push({value: itm.siteCode, text: `${itm.siteCode}: ${itm.siteName}`})
          }
          return resultArr
        }, [{value: "", text: "All My Sites"}])
        this.armsites = {
          ...this.armsites,
          options: siteOptions,
          value: siteOptions[0],
        }
      })
    },
    filterBySite(data) {
      this.facilities = {
        ...this.facilities,
        siteCode: data.value,
      }
    },
    filterByFacility(data) {
      this.instruments = {
        ...this.instruments,
        facilityCode: data.value,
        siteCode: this.facilities.siteCode,
        readOnly : false,
      }
      // this.getInstrumentsBySiteAndFacility()
    },
    filterByInstrument(data) {
      console.log("filterByInstrument")
    },
    getInstrumentsBySiteAndFacility() {
      this.$store.dispatch("instrument/retrieveInstrumentsByPersonIdWithFilters", {siteCode: this.facilities.siteCode, facilityCode: this.instruments.facilityCode}).then((data) => {
        const itms = []
        data.forEach((itm, i) => {
          itms.push({
            instrumentClassCode: itm["instrumentClassCode"],
            instrumentName: itm["instrumentName"],
          })
        })
        this.instruments.options = itms
      }).catch(e => console.log(e))
    },
    handleQuickFilter(data) { //recieves sitecode from quickfilter
      console.log(data)
      if (this.quickFiltered.includes(data)) {
        this.quickFiltered.splice(this.quickFiltered.indexOf(data), 1)
        this.$bvToast.toast(`Instrument ${data.instrumentClassCode} displayed`, {
          title: `Instrument Displayed`,
          autoHideDelay: 5000,
          variant: "success",
        })
      } else {
        this.quickFiltered.push(data)
        this.$bvToast.toast(`Instrument ${data.instrumentClassCode} hidden`, {
          title: `Instrument Hidden`,
          autoHideDelay: 5000,
          variant: "success",
        })
      }
    },
    clearDates(event) {
      event.preventDefault()
      const newKey = this.$store.state.instrument.componentKey + 1
      console.log(newKey)
      this.$store.dispatch("instrument/updateComponentKey", newKey)
      this.masterStartDate = null
      this.masterEndDate = null
    },
  },
}
</script>
<template>
  <div id="timelineView">
    <div class="SearchTopBar PageTopBar">
      <b-form>
        <b-row>
          <b-col v-if="showSites">
            <label class="text-light">Sites</label>
            <SitePickerStatic :value="armsites" @change="filterBySite"/>
          </b-col>
          <b-col>
            <label class="text-light">Facility</label>
            <PersonFacilityPicker v-model="facilities" @change="filterByFacility"/>
          </b-col>
          <b-col>
            <label class="text-light">Instrument</label>
            <InstrumentPickerStatic v-model="instruments" @selectionsChanged="filterByInstrument"/>
          </b-col>
        </b-row>
        <b-row>
          <Toggles @toggle="handleToggle" />
        </b-row>
        <b-row>
          <b-col cols="12" md="3"/>
          <b-col cols="12" md="6">
            <div class="date-picker-container">
              <input v-model="masterStartDate" class="date-picker" type="date" @change="masterStartDate = $event.target.value"/>
              <input v-model="masterEndDate" class="date-picker" type="date" @change="masterEndDate = $event.target.value"/>
              <button class="btn btn-success text-light rounded-0" @click="handleSubmitDates($event)">Submit Dates</button>
              <button class="btn btn-danger text-light rounded-0" @click="clearDates($event)">Clear Dates</button>
            </div>
          </b-col>
          <b-col cols="12" md="3"/>
        </b-row>
      </b-form>
    </div>
    <b-container fluid class="container-fluid gx-0">
      <b-row class="gx-0">
        <b-col class="gx-0 px-0">
          <ColorCodeLegend />
        </b-col>
      </b-row>
    </b-container>
    <!-- <InstrumentList :key="componentKey" ref="instrumentList" :instrumentList="filteredPersonInstruments" /> -->
    <InstrumentList  :key="componentKey" ref="instrumentList" :instrumentList="filteredPersonInstruments" :masterStartDate="submittedStartDate" :masterEndDate="submittedEndDate"/>
    <!--@clear-dates="resetAllDates"-->
  </div>
</template>
<style>
#timelineView {
  font-weight: bold;
}
.date-picker-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.date-picker,
.submit-button {
  height: 40px;
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.date-picker {
  width: auto;
  flex: 1;
}
.submit-button {
  white-space: nowrap;
}
.results-text {
  text-align: center;
}
</style>
