<script>
import SitePicker from "@/components/global/SitePicker"
import InstrumentClassPicker from "@/components/global/InstrumentClassPicker"
import FacilityPicker from "@/components/global/FacilityPicker"
import SearchResults from "@/components/global/SearchResults"
import MyViewInstrumentList from "@/components/instrument/MyViewInstrumentList.vue"


export default {
  components: {
    SitePicker,
    InstrumentClassPicker,
    FacilityPicker,
    MyViewInstrumentList,
  },
  data() {
    return {
      armsites: {
        id: "armsites",
        label: "Sites",
        placeholder: "All Sites",
        defaultLabel: "All Sites",
        multiple: true,
        value: [],
        options: [],
      },
      instruments: {
        id: "instruments",
        label: "Instruments",
        placeholder: "All Instruments",
        multiple: false,
        value: [],
        options: [],
        retrieveAllOnCreate: true,
      },
      currentFacilityData: {
        id: "currentFacility",
        defaultLabel: "Current Facility: All",
        placeholder: "All Facilities",
        siteCode: "",
        readOnly: true,
        value: "",
        options: [],
      },
      selectedInstrument : null, // [siteCode, facilityCode, instrumentCode, personId, visible]
      resultsFields: [
        {
          key: "siteCode",
          label: "Site",
          sortable: true,
        },
        {
          key: "facilityCode",
          label: "Facility",
          sortable: true,
        },
        {
          key: "instrumentClassCode",
          label: "Instrument",
          sortable: true,
        },
        {
          key: "visibilityToggle",
          label: "Visible",
          sortable: true,
        },
        { 
          key: "actions",
          label: "",
        },
      ],
      resultsRecords: [],
    }
  },
  computed: {
    personId() {
      return this.$store.getters["auth/personId"]
    },
  },
  created() {
    this.getPersonInstruments()
    console.log(this.$store.getters["instrument/personInstruments"])
  },
  methods: {
    filterBySite(data) {
      console.log("filteredBysite") //load facilites available at selected Site (in data)
      this.currentFacilityData = {
        ...this.currentFacilityData, 
        siteCode: data.value,
        defaultLabel: data.value,
        readOnly : false,
      }
    },
    filterByFacility(data) {
      console.log("filteredByFacility") //load instrumnts available at selected Facility (in data)
      this.instruments = {
        ...this.instruments, 
        facilityCode: data.value,
        defaultLabel: data.value,
        readOnly : false,
      }
      this.getInstrumentsBySiteAndFacility()
    },
    getPersonInstruments() {
      this.$store.dispatch("instrument/retrieveInstrumentsByPersonId").then((data) => {
        const itms = []
        console.log(data)
        data.forEach((item) => {
          itms.push({
            facilityCode: item["facilityCode"],
            instrumentClassCode: item["instrumentClassCode"],
            personId: item["personId"],
            siteCode: item["siteCode"],
            visible: item["visible"],
            nodeId: item["nodeId"],
          })
        })
        this.resultsRecords = itms
      }).catch(e => console.log(e))
    },
    getInstrumentsBySiteAndFacility() {
      this.$store.dispatch("instrument/retrieveInstrumentsBySiteAndFacility", {siteCode: this.currentFacilityData.siteCode, facilityCode: this.instruments.facilityCode}).then((data) => {
        const itms = []
        data.forEach((itm, i) => {
          itms.push({
            instrumentClassCode: itm["instrumentClassCode"],
            instrumentName: itm["instrumentName"],
          })
        })
        this.instruments.options = itms
      }).catch(e => console.log(e))
    },
    updateInstrumentToAdd(data) {
      if (data !== null) {
        this.selectedInstrument = {
          instrumentClassCode: data.value,
          facilityCode: this.currentFacilityData.value,
          siteCode: this.currentFacilityData.siteCode,
          personId: this.$store.getters["auth/personId"],
          visible: true,
        }
      }
    },
    addInstrument() {
      // console.log(this.$store.getters["instrument/personInstruments"])
      const personInstruments = this.$store.getters["instrument/personInstruments"] //get first item which contains array of instruments
      console.log(personInstruments)
      const contained = personInstruments.filter((i) => {
        return this.selectedInstrument["instrumentClassCode"] == i["instrumentClassCode"] &&
          this.selectedInstrument["facilityCode"] == i["facilityCode"] &&
          this.selectedInstrument["siteCode"] == i["siteCode"] 
      })
      if (contained.length > 0) {
        this.$bvToast.toast(`This Instrument Already Added in your view.`, {
          title: "Duplicate Instrument",
          autoHideDelay: 5000,
          variant: "success",
        })
      } else {
        this.$store.dispatch("instrument/updatePersonInstruments", this.selectedInstrument).then((data) => {
          this.getPersonInstruments() // get new instrument list from store
          this.$bvToast.toast(`Your modifications have been saved.`, {
            title: "New Instrument Added",
            autoHideDelay: 5000,
            variant: "success",
          })
        })
        
      }
    },
  },
}
</script>

<template>
  <div id="myView">
    <div class="SearchTopBar PageTopBar">
      <b-form>
        <b-row class="">
          <b-col>
            <label class="text-light">Site</label>
            <SitePicker :value="armsites" @change="filterBySite"/>
          </b-col>
          <b-col>
            <label class="text-light">Facility</label>
            <FacilityPicker v-model="currentFacilityData" @change="filterByFacility"/>
          </b-col>
          <b-col>
            <label class="text-light">Instrument</label>
            <InstrumentClassPicker v-model="instruments" @selectionsChanged="updateInstrumentToAdd"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="d-flex justify-content-end">
            <b-button variant="success" @click="addInstrument">Add</b-button>
          </b-col>
        </b-row>
      </b-form>
      
    </div>
    <div>
      <!--<SearchResults :fields="resultsFields" :records="resultsRecords"/>-->
      <MyViewInstrumentList :fields="resultsFields" :records="resultsRecords"/>
    </div>
  </div>
</template>

<style>
#myView {
  font-weight: bold;
}
</style>
