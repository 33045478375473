<script>
export default {
  name: "ColorCodeLegend",
  data() {
    return {
      colorCodes : [],
    }
  },
  created() {
    this.getColorCodes()
  },
  methods: {
    getColorCodes() {
      this.$store.dispatch("instrument/retrieveColorCodes").then((data) => {
        this.colorCodes = data
      })
    },
  },
}
</script>
<template>
  <div class="legendContainer px-4 py-2">
    <p class="font-weight-bold fs-5 mb-0">LEGEND</p>
    <div class="d-flex">
      <div v-for="code in colorCodes" :key="code.codeColor" class="d-flex align-items-center mr-3">
        <div class="colorBox border border-light" :style="{ background: code.codeColor }"/>
        <label class="ml-1 mb-0">{{code.codeDesc}}</label>
      </div>
    </div>
  </div>
</template>
<style scoped>
    .colorBox{
        width: 14px;
        height: 14px;
    }
    .legendContainer{
        background: #95c8fb;
    }
</style>
