import axios from "axios"
import Config from "../config"

const instrument = {
  namespaced: true,
  state: {
    debug: false,
    personInstruments: [],
    personAllInstruments: [],
    instruments: [],
    colorCodes: [],
    datastreamGroupsFilter: [],
    assignedDqrInfo: [],
    dqprs: [],
    filters: [],
    tableViewFilters: [],
    dqas: [],
    dqrDetailURL: Config.dqrDetailURL,
    dqaDetailURL: Config.dqaDetailURL,
    dqprDetailURL: Config.dqprDetailURL,

    cmrURL: Config.cmrURL,
    pmrURL: Config.pmrURL,
    showDQR: true,
    showDQA: true,
    showCM: true,
    showPM: true,
    masterStartDate: null,
    masterEndDate: null,
    datesCleared: false,
    componentKey: 0,
  },
  getters: {
    personInstruments(state) {
      return state.personInstruments
    },
    personAllInstruments(state) {
      return state.personAllInstruments
    },
    personAvailableSites(state) {
      const siteCodes = state.personAllInstruments.map(instr => instr.siteCode)
      const unique = Array.from(new Set(siteCodes))
      return unique
    },
    personAvailableFacilities(state) {
      const facilityCodes = state.personAllInstruments.map(instr => instr.facilityCode)
      const unique = Array.from(new Set(facilityCodes))
      return unique
    },
    datastreamGroupsFilter(state) {
      return state.datastreamGroupsFilter
    },
    getAssignedDqrInfo(state) {
      return state.assignedDqrInfo
    },
    getDqaInfo(state) {
      return state.dqas
    },
    getDqprInfo(state) {
      return state.dqprs
    },
    getTableViewFilters(state) {
      return state.tableViewFilters
    },
    
    //Environment specifc URLs for Assigned DQRs, DQAs, DQPRs
  
    getDQRDetailURL(state) {
      return state.dqrDetailURL
    },
    getDQADetailURL(state) {
      return state.dqaDetailURL
    },
    getDQPRDetailURL(state) {
      return state.dqprDetailURL
    },
    getCmrURL(state) {
      return state.cmrURL
    },
    getPmrURL(state) {
      return state.pmrURL
    },
    getCmrURL(state) {
      return state.cmrURL
    },
    showDQR(state) {
      return state.showDQR
    },
    showDQA(state) {
      return state.showDQA
    },
    showCM(state) {
      return state.showCM
    },
    showPM(state) {
      return state.showPM
    },
    masterStartDate(state) {
      return state.masterStartDate
    },
    masterEndDate(state) {
      return state.masterEndDate
    },
    datesCleared(state) {
      return state.datesCleared
    },
    componentKey(state) {
      return state.componentKey
    },
  },
  mutations: {
    setComponentKey(state, newKey) {
      state.componentKey = newKey
    },
    updatePersonInstruments(state, instr) { //instr is an ARRAY of instruments to populate
      state.personInstruments = instr
    },
    updateAllPersonInstruments(state, instr) { //instr is an ARRAY of instruments to populate
      state.personAllInstruments = instr
    },
    removePersonInstrument(state, instr) {
      let removeIdx = null
      state.personInstruments.forEach(itm => {
        if (itm.nodeId == instr.nodeId) {
          removeIdx = itm
        }
      })
      if (removeIdx !== null) {
        state.personInstruments.splice(removeIdx, 1)
      }    
    },
    updatePersonInstrument(state, instr) { //instr should be an ARRAY of one instrument
      const newInstrList = state.personInstruments.map((obj)=> { 
        if (obj.nodeId == instr.nodeId) {
          obj = instr
          return obj
        } else {
          return obj
        }
      })
      state.personInstruments = newInstrList   
    },
    updateInstruments(state, instr) {
      state.instruments.push(instr)
    },
    setColorCodes(state, codes) {
      state.colorCodes = codes
    },
    setAssignedDqrInfo(state, items) {
      state.assignedDqrInfo = items
    },
    setDqaInfo(state, items) {
      state.dqas = items
    },
    setDqprInfo(state, items) {
      state.dqprs = items
    },
    addTableViewFilter(state, table) {
      if (!state.tableViewFilters.includes(table)) {
        state.tableViewFilters.push(table)
      }
    },
    removeTableViewFilter(state, table) {
      const index = state.tableViewFilters.indexOf(table)
      if (index > -1) {
        state.tableViewFilters.splice(index, 1)
      }
    },
    updateDqas(state, items) {
      state.dqas = items
    },
    setDQR(state, value) {
      state.showDQR = value
    },
    setDQA(state, value) {
      state.showDQA = value
    },
    setCM(state, value) {
      state.showCM = value
    },
    setPM(state, value) {
      state.showPM = value
    },
    //setMasterStartDate
    setMasterStartDate(state, date) {
      state.masterStartDate = date
    },
    setMasterEndDate(state, date) {
      state.masterEndDate = date
    },
    clearMasterDates(state) {
      state.masterStartDate = null
      state.masterEndDate = null
      state.datesCleared = true
    },
    resetDatesCleared(state) {
      state.datesCleared = false
    },
  },
  actions: {
    updateComponentKey({ commit }, newKey) {
      commit("setComponentKey", newKey)
    },
    resetDatesCleared({ commit }) {
      commit("resetDatesCleared")
    },
    clearMasterDates({ commit }) {
      commit("clearMasterDates")
    },
    setMasterStartDate({ commit }, date) {
      commit("setMasterStartDate", date)
    },
    setMasterEndDate({ commit }, date) {
      commit("setMasterEndDate", date)
    },
    toggleDQR({ commit, state }) {
      commit("setDQR", !state.showDQR)
    },
    toggleDQA({ commit,state }) {
      commit("setDQA", !state.showDQA)
    },
    toggleCM({ commit,state }) {
      commit("setCM", !state.showCM)
    },
    togglePM({ commit,state }) {
      commit("setPM", !state.showPM)
    },
    addTableViewFilter({ commit }, table) {
      commit("addTableViewFilter", table)
    },
    removeTableViewFilter({ commit }, table) {
      commit("removeTableViewFilter", table)
    },
    retrieveInstrumentsBySiteAndFacility({commit, state, rootState}, {siteCode, facilityCode}) {
      //if there is no sitecode make it null 
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {query: `{
            getActiveInstruments(facilityCode: "${facilityCode}", siteCode: "${siteCode}") {
              nodes {
                facilityCode
                instrumentCode
                instrumentName
                siteCode
              }
            }
          }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
        }, 
      })
        .then((response) => {
          const recs = response.data.data.getActiveInstruments.nodes
          commit("updateInstruments", recs)
          return recs
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
      
    },
    retrieveInstrumentsByPersonId({commit, dispatch, state, rootState}) {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {query:`{
            allOperationsDashboardOpsUserInstruments (filter: {personId: {equalTo: ${rootState.auth.personId}}}) {
              nodes {
                siteCode
                facilityCode
                instrumentClassCode
                visible
                nodeId
              }
            }
          }   
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
        }, 
      })
        .then((response) => {
          const recs = response.data.data.allOperationsDashboardOpsUserInstruments.nodes

          dispatch("retrieveAllPersonInstruments", recs)

          commit("updatePersonInstruments", JSON.parse(JSON.stringify(recs)))
          return recs
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
    retrieveAllPersonInstruments({commit, rootState}, recs) {
      if (recs.length == 0) {
        commit("updateAllPersonInstruments", JSON.parse(JSON.stringify([])))
        return []
      }
      let query = `{ allDatastreamInfos(filter: { or: [`

      recs.forEach(itm => {
        let filter = "{"
        if (itm.siteCode != "") {
          filter += `siteCode: { equalTo: "${itm.siteCode}" }\n`
        }
        if (itm.facilityCode != "") {
          filter += `facilityCode: { equalTo: "${itm.facilityCode}" }\n`
        }
        if (itm.instrumentClassCode != "") {
          filter += `instrumentClassCode: { equalTo: "${itm.instrumentClassCode}" }\n`
        }
        filter += "}\n"
        query += filter
      })

      query += `] }) {
          nodes {
            facilityCode
            instrumentClassCode
            siteCode
            visible
          }
        }
      }`

      return axios({
        method: "post",
        url: Config.graphql.host,
        data: { query },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
        }, 
      })
        .then((response) => {
          const recs = response.data.data.allDatastreamInfos.nodes
          const newRecs = []
          recs.forEach(itm => {
            const tmp = {
              facilityCode: itm.facilityCode,
              instrumentClassCode: itm.instrumentClassCode,
              siteCode: itm.siteCode,
              visible: itm.visible == "Y" ? true : false,
            }
            let found = undefined //const found = newRecs.find((ele) => ele == tmp)
            newRecs.forEach(itm => {
              if ((itm.siteCode == tmp.siteCode) && (itm.facilityCode == tmp.facilityCode) && (itm.instrumentClassCode == tmp.instrumentClassCode)) {
                found = itm
              }
            })
            
            if (found == undefined) {
              newRecs.push(tmp)
            }
          })

          commit("updateAllPersonInstruments", JSON.parse(JSON.stringify(newRecs)))
          return newRecs
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
    retrieveInstrumentsByPersonIdWithFilters({commit, state, rootState}, {siteCode=null, facilityCode=null}) {
      if (false/*state.personInstruments.length > 0*/) {
        return state.personInstruments
      } else {
        let filters = `filter: {personId: {equalTo: ${rootState.auth.personId}}`
        if (siteCode !== null) {
          filters += `, siteCode: {equalTo: "${siteCode}"}`
        }
        if (facilityCode !== null) {
          filters += `, facilityCode: {equalTo: "${facilityCode}"}`
        }
        filters += "}"
        const queryStr = `{
          allOperationsDashboardOpsUserInstruments (${filters}) {
            nodes {
              siteCode
              facilityCode
              instrumentClassCode
              visible
              nodeId
            }
          }
        }   
        `

        return axios({
          method: "post",
          url: Config.graphql.host,
          data: {query: queryStr },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
          }, 
        })
          .then((response) => {
            const recs = response.data.data.allOperationsDashboardOpsUserInstruments.nodes
            commit("updatePersonInstruments", JSON.parse(JSON.stringify(recs)))
            return recs
          })
          .catch(error => {
            console.error("Error:", error)
            return []
          })
      }
    },
    updatePersonInstruments({commit, state, rootState}, {facilityCode, instrumentClassCode, siteCode, visible}) {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {query:`mutation {
            createOperationsDashboardOpsUserInstrument(
              input: {
                operationsDashboardOpsUserInstrument: {
                  facilityCode: "${facilityCode}", 
                  instrumentClassCode: "${instrumentClassCode}", 
                  personId: ${rootState.auth.personId},
                  siteCode: "${siteCode}",
                  visible: ${visible}
                }
              }
              ){ 
                operationsDashboardOpsUserInstrument {
                  personId
                  siteCode
                  facilityCode
                  instrumentClassCode
                  visible
                  nodeId
                }
              }
            }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
        }, 
      })
        .then((response) => {
          const recs = response.data.data.createOperationsDashboardOpsUserInstrument.operationsDashboardOpsUserInstrument
          commit("updatePersonInstruments", [recs])
          return recs
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
    retrieveAllFacilities({commit, state, rootState}, siteCode="") {
      let siteFilter = ""
      if (siteCode != "") {
        siteFilter = `(filter: {siteCode: {equalTo: "${siteCode}" }})`
      } 
      const queryStr = `{
        allFacilityInfos ${siteFilter} {
          nodes {
            facilityCode
            facilityName
            siteCode
          }
        }
      }   
      `
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {query: queryStr,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
        }, 
      })
        .then((response) => {
          return response.data.data.allFacilityInfos.nodes
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
    deletePersonInstrument({commit, state, rootState}, nodeId) {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {query:`mutation {
          deleteOperationsDashboardOpsUserInstrument(
            input: {nodeId: "${nodeId}"}
          )
          {
            clientMutationId
            deletedOpsUserInstrumentId
            operationsDashboardOpsUserInstrument {
              facilityCode
              instrumentClassCode
              nodeId
              personId
              siteCode
              visible
            }
          }
        }   
        `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
        }, 
      })
        .then((response) => {
          console.log(response.data.data)
          const instr = response.data.data.deleteOperationsDashboardOpsUserInstrument.operationsDashboardOpsUserInstrument
          commit("removePersonInstrument", JSON.parse(JSON.stringify(instr)))
          return response.data.data.deleteOperationsDashboardOpsUserInstrument.operationsDashboardOpsUserInstrument
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
    updatePersonInstrument({commit, state, rootState}, instr) {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {query:`mutation {
          updateOperationsDashboardOpsUserInstrument(
            input: {
              nodeId: "${instr.nodeId}", 
              operationsDashboardOpsUserInstrumentPatch: {
                facilityCode: "${instr.facilityCode}", 
                instrumentClassCode: "${instr.instrumentClassCode}", 
                personId: ${rootState.auth.personId}, 
                siteCode: "${instr.siteCode}", 
                visible: ${instr.visible}
              }
            }
          )
          {
            clientMutationId
            operationsDashboardOpsUserInstrument {
              facilityCode
              instrumentClassCode
              nodeId
              personId
              siteCode
              visible
            }
          }
        }   
        `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
        }, 
      })
        .then((response) => {
          const instr = response.data.data.updateOperationsDashboardOpsUserInstrument.operationsDashboardOpsUserInstrument
          commit("updatePersonInstrument", JSON.parse(JSON.stringify(instr)))
          return instr
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
    retrieveInstrumentDataStreamInfos({state, rootState}, payload) {
      if (state.debug) console.log("retrieveInstrumentDataStreamInfos: ", payload)
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {query:`query retrieveInstrumentDataStreamInfos($siteCode: String!, $facilityCode: String!, $instrumentClassCode: String!) {
          allDatastreamInfos(
            filter: {
              siteCode: { equalTo: $siteCode }
              facilityCode: { equalTo: $facilityCode }
              instrumentClassCode: { equalTo: $instrumentClassCode }
            }
          ) {
            nodes {
              dataLevelCode
              datastream
              endDate
              facilityCode
              instrumentClassCode
              nodeId
              siteCode
              startDate
              visible
            }
          }
        }   
        `,
        variables: {
          siteCode: payload.siteCode,
          facilityCode: payload.facilityCode,
          instrumentClassCode: payload.instrumentClassCode,
        },
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
        }, 
      })
        .then((response) => {
          return response.data.data.allDatastreamInfos.nodes
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
    retrieveTimelineData({ commit, state, rootState }, payload) {
      const orFilters =[{
        site: { equalTo: payload.site },
        facilityCode: { equalTo: payload.facility },
        instrument: { equalTo: payload.instrument },
      }]
      const constructFilterItem = obj => {
        const parts = []
        for (const key in obj) {
          parts.push(`${key}: {equalTo: "${obj[key].equalTo}"}`)
        }
        return `{${parts.join(", ")}}`
      }
    
      const queryFiltersArray = orFilters.map(constructFilterItem)
      const queryFilters = `filter: { 
        or: [${queryFiltersArray.join(", ")}],
        status: {in: ["Incorrect", "Suspect", "Missing (-9999)", "Data Not Available"]}
      }`
      const dataStreamValues = []

      payload.datastream.forEach(ds => {
        dataStreamValues.push(`"${ds.datastream}"`)
      })
      const dataStreamInQuery = `filter: { datastream: { in: [${dataStreamValues.join(", ")}] } }`

      const cmrFilters =[{
        reportSiteCode: { equalTo: payload.site },
        reportFacilityCode: { equalTo: payload.facility },
        instrumentCode: { equalTo: payload.instrument },
      }]
      const cmrFiltersArray = cmrFilters.map(constructFilterItem)
      const cmrQueryFilters = `filter: { 
        or: [${cmrFiltersArray.join(", ")}],
      }`
      const pmrFilters =[{
        reportSiteCode: { equalTo: payload.site },
        reportFacilityCode: { equalTo: payload.facility },
        reportInstrumentClass: { equalTo: payload.instrument },
      }]
      const pmrFiltersArray = pmrFilters.map(constructFilterItem)
      const pmrQueryFilters = `filter: { 
        or: [${pmrFiltersArray.join(", ")}],
      }`
      
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {query:`{
          dqas: allDqaDqalocs(
            ${queryFilters}
          ) {
            totalCount
            nodes {
              dqaDqaByDqano {
                dqaStartdate
                dqaEnddate
                dqano
              }
              status
            }
          }
          dqrs: allPifcardqr2VarnameMetrics(
            ${dataStreamInQuery}
          ) {
            nodes {
              pifcardqr2DqrColorCodeByMetricValue {
                codeColor
                codeDesc
                longDefinition
              }
              id
              datastream
              endDate
              startDate
              varName 
            }
          }
          cmrs: allCmrReports (
            ${cmrQueryFilters}
          ) {
            nodes {
              instrumentCode
              nodeId
              reportData
              reportDataType
              reportFacilityCode
              reportId
              reportKey
              reportSiteCode
              reportStateCode
              reportUrl
              reportedDate
              reporterId
            }
          }
          pmrs: allPmrReports (
            ${pmrQueryFilters}
          ) {
            nodes {
              nodeId
              reportData
              reportDataType
              reportFacilityCode
              reportFrequencyCode
              reportGroupId
              reportId
              reportInstrumentClass
              reportKey
              reportSiteCode
              reportStateCode
              reportUrl
              reportedDate
              reporterId
            }
          }
        }   
        `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
        }, 
      })
        .then((response) => {
          var dqrsTmp = [], dqrs = []

          response.data.data.dqrs.nodes.forEach( dqr => {
            if (dqrsTmp.indexOf(dqr.id) == -1) {
              dqrsTmp[dqr.id] = []
            }
            if (dqrsTmp[dqr.id].indexOf(dqr.startDate + dqr.endDate) == -1) {
              dqrsTmp[dqr.id][dqr.startDate + dqr.endDate] = dqr
            }
          })
          Object.keys(dqrsTmp).forEach(pKey => {
            Object.keys(dqrsTmp[pKey]).forEach(dKey => {
              dqrs.push(dqrsTmp[pKey][dKey])
            })
          })
          return { dqas: response.data.data.dqas.nodes, dqrs, cmrs:response.data.data.cmrs.nodes, pmrs: response.data.data.pmrs.nodes }
          // response.data.data.allPifcardqr2VarnameMetrics.nodes
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
    retrieveColorCodes({commit, state, rootState}) {
      if (state.colorCodes.length > 0) {
        return state.colorCodes
      } else {
        return axios({
          method: "post",
          url: Config.graphql.host,
          data: {query:`{
          allPifcardqr2DqrColorCodes {
            nodes {
              codeColor
              codeDesc
              codeNum
              longDefinition
              shortDefinition
            }
          }
        }   
        `,
          },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
          }, 
        })
          .then((response) => {
            const recs = response.data.data.allPifcardqr2DqrColorCodes.nodes
            commit("setColorCodes", recs)
            return recs
          })
          .catch(error => {
            commit("setColorCodes", [])
            console.error("Error:", error)
            return []
          })
      }
    },

    retrieveAssignedDqrInfo({ state, rootState }, payload = {}) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const matchingInsts = []
          state.personAllInstruments.forEach(inst => {
            let add = true
            if (payload.site != "") {
              add = inst.siteCode == payload.site
            }
            if (add && (payload.facility != "")) {
              add = inst.facilityCode == payload.facility
            }
            if (add && (payload.instrument != "")) {
              add = inst.instrumentClassCode == payload.instrument
            }
            if (add) {
              matchingInsts.push(inst)
            }
          })
          //console.log(matchingInsts) //gives 29
          const orFilters = matchingInsts.map(item => {
            const siteCode = item.siteCode !== null ? item.siteCode : null
            const facilityCode = item.facilityCode !== null ? item.facilityCode : null
            const instrumentClassCode = item.instrumentClassCode !== null ? item.instrumentClassCode : null
            const obj = {}
            if (siteCode !== null) {
              obj.siteCode = { equalTo: item.siteCode }
            }
            if (facilityCode !== null) {
              obj.facilityCode = { equalTo: item.facilityCode }
            }
            if (instrumentClassCode !== null) {
              obj.instrumentClassCode = { equalTo: item.instrumentClassCode }
            }
            return obj

          })
          const constructFilterItem = (obj) => {
            const parts = []
            for (const key in obj) {
              parts.push(`${key}: {equalTo: "${obj[key].equalTo}"}`)
            }
            return `{${parts.join(", ")}}`
          }
          
          const queryFiltersArray = orFilters.map(constructFilterItem)
          const queryFilters = `filter: { or:[${queryFiltersArray.join(", ")}]}`
          const opts = {
            method: "post",
            url: Config.graphql.host,
            data: {
              query: `{ allAssignedDqrsVws 
                (
                ${queryFilters}
                ) {
                nodes {
                  facilityCode
                  id
                  instrumentClassCode
                  siteCode
                  statusDesc
                  subject
                  status
                }
              }
            }`,
            },
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              authorization: `Bearer ${rootState.auth.graphqlTicket}`,
            },
          }
          axios(opts).then(response => {
            // console.log(response.data.data.allAssignedDqrsVws.nodes)
            resolve(response.data.data.allAssignedDqrsVws.nodes)
          }).catch(error => {
            console.error("Error", error)
            reject(error)
          })
        }, 100)
      })
    },

    retrieveDqaInfo({ state, rootState }, payload = {}) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const matchingInsts = []
          state.personAllInstruments.forEach(inst => {
            let add = true
            if (payload.site != "") {
              add = inst.siteCode == payload.site
            }
            if (add && (payload.facility != "")) {
              add = inst.facilityCode == payload.facility
            }
            if (add && (payload.instrument != "")) {
              add = inst.instrumentClassCode == payload.instrument
            }
            if (add) {
              matchingInsts.push(inst)
            }
          })
          const orFilters = matchingInsts.map(item => {
            const site = item.siteCode !== null ? item.siteCode : null
            const facilityCode = item.facilityCode !== null ? item.facilityCode : null
            const instrument = item.instrumentClassCode !== null ? item.instrumentClassCode : null
            const obj = {}
            if (site !== null) {
              obj.site = { equalTo: item.siteCode}
            }
            if (facilityCode !== null) {
              obj.facilityCode = { equalTo: item.facilityCode}
            }
            if (instrument !== null) {
              obj.instrument = { equalTo: item.instrumentClassCode}
            }
            return obj

          })
          const constructFilterItem = (obj) => {
            const parts = []
            for (const key in obj) {
              parts.push(`${key}: {equalTo: "${obj[key].equalTo}"}`)
            }
            return `{${parts.join(", ")}}`
          }
          
          const queryFiltersArray = orFilters.map(constructFilterItem)
          const queryFilters = `filter: { or:[${queryFiltersArray.join(", ")}]}`
        
          const opts = {
            method: "post",
            url: Config.graphql.host,
            data: {
              query: `{
                allDqaDqalocs (
                  ${queryFilters}
                  ) {
                  nodes {
                    dqano
                    comment
                    facilityCode
                    instrument
                    site
                    status
                    dqaDqaByDqano {
                      dqaEnddate
                      dqaStartdate
                  }
                  }
                }
              } 
            `,
            },
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              authorization: `Bearer ${rootState.auth.graphqlTicket}`,
            },
          }
          axios(opts).then(response => {
            resolve(response.data.data.allDqaDqalocs.nodes)
          }).catch(error => {
            console.error("Error", error)
            reject(error)
          })
        }, 500)
      })
    },
    retrieveDqprs({ state, rootState }, payload = {}) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const matchingInsts = []
          state.personAllInstruments.forEach(inst => {
            let add = true
            if (payload.site != "") {
              add = inst.siteCode == payload.site
            }
            if (add && (payload.facility != "")) {
              add = inst.facilityCode == payload.facility
            }
            if (add && (payload.instrument != "")) {
              add = inst.instrumentClassCode == payload.instrument
            }
            if (add) {
              matchingInsts.push(inst)
            }
          })
          const orFilters = matchingInsts.map(item => {
            const site = item.site !== null ? item.site : null
            const facilityCode = item.facilityCode !== null ? item.facilityCode : null
            const instrument = item.instrument !== null ? item.instrumentClassCode : null
            const obj = {}
            if (site !== null) {
              obj.site = { equalTo: item.siteCode}
            }
            if (facilityCode !== null) {
              obj.facilityCode = { equalTo: item.facilityCode}
            }
            if (instrument !== null) {
              obj.instrument = { equalTo: item.instrumentClassCode}
            }
            return obj

          })
          const constructFilterItem = (obj) => {
            const parts = []
            for (const key in obj) {
              parts.push(`${key}: {equalTo: "${obj[key].equalTo}"}`)
            }
            return `{${parts.join(", ")}}`
          }
          
          const queryFiltersArray = orFilters.map(constructFilterItem)
          const queryFilters = `filter: { 
            or:[${queryFiltersArray.join(", ")}]
            dqpr2DqprByDqprno: {
              dqpr2StatusByDqprStatus: {
                statusval: { notIn: [3,6,9999,12]},
              }
            }
          }`
          const opts = {
            method: "post",
            url: Config.graphql.host,
            data: {
              query: `{ allDqpr2Dqprlocs
                (
                ${queryFilters} 
                ) {
                  nodes {
                    dqprno
                    facilityCode
                    site
                    instrument
                    dqpr2DqprByDqprno {
                      dqpr2StatusByDqprStatus {
                        statusdesc
                      }
                      dqpr2QacodeByQacode {
                        codedesc
                      }
                      entryDate
                    }
                  }
                }
              }
            `,
            },
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              authorization: `Bearer ${rootState.auth.graphqlTicket}`,
            },
          }
          axios(opts).then(response => {
            resolve(response.data.data.allDqpr2Dqprlocs.nodes)
          }).catch(error => {
            console.error("Error", error)
            reject(error)
          })
        }, 500)
      })
    },
    retrievePmrInfo({ state, rootState }, payload = {}) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const matchingInsts = []
          state.personAllInstruments.forEach(inst => {
            let add = true
            if (payload.site != "") {
              add = inst.siteCode == payload.site
            }
            if (add && (payload.facility != "")) {
              add = inst.facilityCode == payload.facility
            }
            if (add && (payload.instrument != "")) {
              add = inst.instrumentClassCode == payload.instrument
            }
            if (add) {
              matchingInsts.push(inst)
            }
          })
          // reportSiteCode: { equalTo: payload.site },
          // reportFacilityCode: { equalTo: payload.facility },
          // reportInstrumentClass: { equalTo: payload.instrument },
          const orFilters = matchingInsts.map(item => {
            const siteCode = item.siteCode !== null ? item.siteCode : null
            const facilityCode = item.facilityCode !== null ? item.facilityCode : null
            const instrumentClassCode = item.instrumentClassCode !== null ? item.instrumentClassCode : null
            const obj = {}
            if (siteCode !== null) {
              obj.reportSiteCode = { equalTo: item.siteCode }
            }
            if (facilityCode !== null) {
              obj.reportFacilityCode = { equalTo: item.facilityCode }
            }
            if (instrumentClassCode !== null) {
              obj.reportInstrumentClass = { equalTo: item.instrumentClassCode }
            }
            return obj

          })
          console.log(orFilters)
          const constructFilterItem = (obj) => {
            const parts = []
            for (const key in obj) {
              parts.push(`${key}: {equalTo: "${obj[key].equalTo}"}`)
            }
            return `{${parts.join(", ")}}`
          }
          
          const queryFiltersArray = orFilters.map(constructFilterItem)
          const queryFilters = `filter: { or:[${queryFiltersArray.join(", ")}]}`

          console.log(queryFilters)
          const opts = {
            method: "post",
            url: Config.graphql.host,
            data: {
              query: `{ allPmrReports 
                (
                ${queryFilters}
                ) {
                nodes {
                  nodeId
                  reportData
                  reportDataType
                  reportFacilityCode
                  reportFrequencyCode
                  reportGroupId
                  reportId
                  reportInstrumentClass
                  reportKey
                  reportSiteCode
                  reportStateCode
                  reportUrl
                  reportedDate
                  reporterId
                  risId
                }
              }
            }`,
            },
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              authorization: `Bearer ${rootState.auth.graphqlTicket}`,
            },
          }
          axios(opts).then(response => {
            console.log(response)
            resolve(response.data.data.allPmrReports.nodes)
          }).catch(error => {
            console.error("Error", error)
            reject(error)
          })
        }, 100)
      })
    },

    retrieveCmrInfo({ state, rootState }, payload = {}) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const matchingInsts = []
          state.personAllInstruments.forEach(inst => {
            let add = true
            if (payload.site != "") {
              add = inst.siteCode == payload.site
            }
            if (add && (payload.facility != "")) {
              add = inst.facilityCode == payload.facility
            }
            if (add && (payload.instrument != "")) {
              add = inst.instrumentClassCode == payload.instrument
            }
            if (add) {
              matchingInsts.push(inst)
            }
          })
          // reportSiteCode: { equalTo: payload.site },
          // reportFacilityCode: { equalTo: payload.facility },
          // reportInstrumentClass: { equalTo: payload.instrument },
          const orFilters = matchingInsts.map(item => {
            const siteCode = item.siteCode !== null ? item.siteCode : null
            const facilityCode = item.facilityCode !== null ? item.facilityCode : null
            const instrumentClassCode = item.instrumentClassCode !== null ? item.instrumentClassCode : null
            const obj = {}
            if (siteCode !== null) {
              obj.reportSiteCode = { equalTo: item.siteCode }
            }
            if (facilityCode !== null) {
              obj.reportFacilityCode = { equalTo: item.facilityCode }
            }
            if (instrumentClassCode !== null) {
              obj.instrumentCode = { equalTo: item.instrumentClassCode }
            }
            return obj

          })
          console.log(orFilters)
          const constructFilterItem = (obj) => {
            const parts = []
            for (const key in obj) {
              parts.push(`${key}: {equalTo: "${obj[key].equalTo}"}`)
            }
            return `{${parts.join(", ")}}`
          }
          
          const queryFiltersArray = orFilters.map(constructFilterItem)
          const queryFilters = `filter: { or:[${queryFiltersArray.join(", ")}]}`

          console.log(queryFilters)
          const opts = {
            method: "post",
            url: Config.graphql.host,
            data: {
              query: `{ allCmrReports 
                (
                ${queryFilters}
                ) {
                  nodes {
                    instrumentCode
                    nodeId
                    reportData
                    reportDataType
                    reportFacilityCode
                    reportId
                    reportKey
                    reportSiteCode
                    reportStateCode
                    reportUrl
                    reportedDate
                    reporterId
                }
              }
            }`,
            },
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              authorization: `Bearer ${rootState.auth.graphqlTicket}`,
            },
          }
          axios(opts).then(response => {
            console.log(response)
            resolve(response.data.data.allCmrReports.nodes)
          }).catch(error => {
            console.error("Error", error)
            reject(error)
          })
        }, 100)
      })
    },
  },
}

export default instrument
