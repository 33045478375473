<script>
import TableGenericList from "@/components/instrument/TableGenericList"
export default {
  name: "AssignedDqrTable",
  components: {
    TableGenericList,
  },
  props: {
    site: { value: String, default: "" },
    facility: { value: String, default: "" },
    instrument: { value: String, default: "" },
  },
  data() {
    return {
      debounceTimer: null,
      dqrInfo: [],
      dqrs: [],
      fields: [
        {
          key: "id",
          label: "DQR ID",
          sortable: true,
        },
        {
          key: "statusDesc",
          label: "STATUS",
          sortable: true,
        },
        {
          key: "siteCode",
          label: "SITE",
          sortable: true,
        },
        {
          key: "facilityCode",
          label: "FACILITY",
          sortable: true,
        },
        { 
          key: "instrumentClassCode",
          label: "SYSTEM",
          sortable: true,
        },
      ],
    }
  },
  computed: {
    itemsLoaded() {
      return this.dqrInfo.length > 0
    },
  },
  watch: {
    site: "updateDqrInfo",
    facility: "updateDqrInfo",
    instrument: "updateDqrInfo",
  },
  created() {
    this.getAssignedDqrInfo()
  },
  methods: {
    getAssignedDqrInfo() {
      this.$store.dispatch("instrument/retrieveAssignedDqrInfo", {
        site: this.site, 
        facility: this.facility, 
        instrument: this.instrument,
      }).then((data) => {
        // console.log(data)
        this.dqrInfo = data
      })
    },
    updateDqrInfo() {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        this.$store.dispatch("instrument/retrieveAssignedDqrInfo", {
          site: this.site, 
          facility: this.facility, 
          instrument: this.instrument,
        }).then(data => {
          this.dqrInfo = data
        })
      }, 100)
    },
  },
}
</script>
<template>
  <div id="tableView">
    <b-container class="gx-0">
      <b-row class="gx-0">
        <b-col class="gx-0">
          <div id="assignedDqrs" class="tableContainer">
            <h6 class="font-weight-bold pt-2 px-2 pb-0 mb-0 sectionHeader">Assigned DQRs</h6>
            <TableGenericList :fields="fields" :records="dqrInfo"/>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<style scoped>
    .tableContainer{
        background: #95c8fb;
    }
</style>
