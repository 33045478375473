<script>
import TableGenericList from "@/components/instrument/TableGenericList"

export default {
  name: "DQPRTable",
  components: {
    TableGenericList,
  },
  props: {
    site: { value: String, default: "" },
    facility: { value: String, default: "" },
    instrument: { value: String, default: "" },
  },
  data() {
    return {
      debounceTimer: null,
      dqprInfo: [],
      dqprs: [],
      fields: [
        {
          key: "dqprno",
          label: "RECORD",
          sortable: true,
        },
        {
          key: "dqpr2DqprByDqprno.dqpr2QacodeByQacode.codedesc",
          label: "QA Code",
          sortable: true,
        },
        { 
          key: "dqpr2DqprByDqprno.dqpr2StatusByDqprStatus.statusdesc",
          label: "LAST COMMENT",
          sortable: true,
        },
        { 
          key: "formattedEntryDate",
          label: "ISSUE DATE",
          sortable: true,
        },
      ],
    }
  },
  computed: {
    itemsLoaded() {
      return this.dqprInfo.length > 0
    },
  },
  watch: {
    site: "updateDqprInfo",
    facility: "updateDqprInfo",
    instrument: "updateDqprInfo",
  },
  created() {
    this.getDqprInfo()
      
  },
  methods: {
    getDqprInfo() {
      this.$store.dispatch("instrument/retrieveDqprs", {
        site: this.site,
        facility:this.facility,
        instrument: this.instrument,
      }).then((data) => {
        this.dqprDateFormatter(data)
        this.dqprInfo = data
      })
    },
    updateDqprInfo() {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        this.$store.dispatch("instrument/retrieveDqprs", {
          site: this.site, 
          facility: this.facility, 
          instrument: this.instrument,
        }).then(data => {
          this.dqprDateFormatter(data)
          this.dqprInfo = data
        })
      }, 100)
    },
    formattedDateValue(dateValue) {
      const dateObject = new Date(dateValue)
      const month = String(dateObject.getMonth() + 1).padStart(2, "0")
      const day = String(dateObject.getDate()).padStart(2, "0")
      const year = dateObject.getFullYear()
      return `${month}/${day}/${year}`
    },
    dqprDateFormatter(data) {
      data.forEach(item => {
        if (item.dqpr2DqprByDqprno.hasOwnProperty("entryDate")) {
          const entryDateValue = item.dqpr2DqprByDqprno.entryDate
          item.formattedEntryDate = this.formattedDateValue(entryDateValue)
          return item.formattedEntryDate
        }
      })       
    },
  },
}
</script>
<template>
  <div id="tableView">
    <b-container class="gx-0">
      <b-row class="gx-0">
        <b-col class="gx-0">
          <div id="DQPRs" class="tableContainer">
            <h6 class="font-weight-bold pt-2 px-2 pb-0 mb-0 sectionHeader">DQPRs</h6>
            <TableGenericList :fields="fields" :records="dqprInfo"/>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<style scoped>
    .tableContainer{
        background: #95c8fb;
    }
</style>
