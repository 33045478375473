<script>
import TableGenericList from "@/components/instrument/TableGenericList"
export default {
  name: "CmrTable",
  components: {
    TableGenericList,
  },
  props: {
    site: { value: String, default: "" },
    facility: { value: String, default: "" },
    instrument: { value: String, default: "" },
  },
  data() {
    return {
      debounceTimer: null,
      cmrInfo: [],
      cmrs: [],
      fields: [
        {
          key: "formattedReportedDate",
          label: "Date",
          sortable: true,
        },
        {
          key: "reportSiteCode",
          label: "SITE",
          sortable: true,
        },
        {
          key: "reportFacilityCode",
          label: "FACILITY",
          sortable: true,
        },
        { 
          key: "instrumentCode",
          label: "SYSTEM",
          sortable: true,
        },
        { 
          key: "reportStateCode",
          label: "DESCRIPTION",
          sortable: true,
        },
      ],
    }
  },
  computed: {
    itemsLoaded() {
      return this.cmrInfo.length > 0
    },
  },
  watch: {
    site: "updateCmrInfo",
    facility: "updateCmrInfo",
    instrument: "updateCmrInfo",
  },
  created() {
    this.getCmrInfo()
  },
  methods: {
    getCmrInfo() {
      this.$store.dispatch("instrument/retrieveCmrInfo", {
        site: this.site, 
        facility: this.facility, 
        instrument: this.instrument,
      }).then((data) => {
        // console.log(data)
        this.cmrDateFormatter(data)
        this.cmrInfo = data
      })
    },
    updateCmrInfo() {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        this.$store.dispatch("instrument/retrieveCmrInfo", {
          site: this.site, 
          facility: this.facility, 
          instrument: this.instrument,
        }).then(data => {
          this.cmrDateFormatter(data)
          this.cmrInfo = data
        })
      }, 100)
    },
    formattedDateValue(dateValue) {
      const dateObject = new Date(dateValue)
      const month = String(dateObject.getMonth() + 1).padStart(2, "0")
      const day = String(dateObject.getDate()).padStart(2, "0")
      const year = dateObject.getFullYear()
      return `${month}/${day}/${year}`
    },
    cmrDateFormatter(data) {
      data.forEach(item => {
        if (item.hasOwnProperty("reportedDate")) {
          const newReportedDate = item.reportedDate
          item.formattedReportedDate = this.formattedDateValue(newReportedDate)
          return item.formattedReportedDate
        }
      })       
    },
  },
}
</script>
<template>
  <div id="tableView">
    <b-container class="gx-0">
      <b-row class="gx-0">
        <b-col class="gx-0">
          <div id="assignedDqrs" class="tableContainer">
            <h6 class="font-weight-bold pt-2 px-2 pb-0 mb-0 sectionHeader">CM Reports</h6>
            <TableGenericList :fields="fields" :records="cmrInfo"/>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<style scoped>
    .tableContainer{
        background: #95c8fb;
    }
</style>
