<script>
import TableGenericList from "@/components/instrument/TableGenericList"

export default {
  name: "DQATable",
  components: {
    TableGenericList,
  },
  props: {
    site: { value: String, default: "" },
    facility: { value: String, default: "" },
    instrument: { value: String, default: "" },
  },
  data() {
    return {
      debounceTimer: null,
      dqaInfo: [],
      fields: [
        {
          key: "dqano",
          label: "DQA NO",
          sortable: true,
        },
        {
          key: "site",
          label: "SITE",
          sortable: true,
        },
        {
          key: "facilityCode",
          label: "FACILITY",
          sortable: true,
        },
        { 
          key: "instrument",
          label: "SYSTEM",
          sortable: true,
        },
        { 
          key: "finalDate",
          label: "DATES",
          sortable: true,
        },
        { 
          key: "comment",
          label: "ASSESSMENT",
          sortable: true,
        },
      ],
    }
  },
  computed: {
    itemsLoaded() {
      return this.dqaInfo.length > 0
    },
  },
  watch: {
    site: "updateDqaInfo",
    facility: "updateDqaInfo",
    instrument: "updateDqaInfo",
  },
  created() {
    this.getDqaInfo()
  },
  methods: {
    getDqaInfo() {
      this.$store.dispatch("instrument/retrieveDqaInfo", {
        site: this.site,
        facility:this.facility,
        instrument: this.instrument,
      }).then((data) => {
        this.dqaDateFormatter(data)
        this.dqaInfo = data
      })
    }, 
    updateDqaInfo() {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        this.$store.dispatch("instrument/retrieveDqaInfo", {
          site: this.site, 
          facility: this.facility, 
          instrument: this.instrument,
        }).then(data => {
          this.dqaDateFormatter(data)
          this.dqaInfo = data
        })
      }, 100)
    },  
    formattedDateValue(dateValue) {
      const dateObject = new Date(dateValue)
      const month = String(dateObject.getMonth() + 1).padStart(2, "0")
      const day = String(dateObject.getDate()).padStart(2, "0")
      const year = dateObject.getFullYear()
      return `${month}/${day}/${year}`
    },

    dqaDateFormatter(data) {
      data.forEach(item => {
        if (item.dqaDqaByDqano.hasOwnProperty("dqaStartdate") && item.dqaDqaByDqano.hasOwnProperty("dqaEnddate")) {
          const dqaStartDateValue = item.dqaDqaByDqano.dqaStartdate
          const dqaEndDateValue = item.dqaDqaByDqano.dqaEnddate
          item.formattedStartDate = this.formattedDateValue(dqaStartDateValue)
          item.formattedEndDate = this.formattedDateValue(dqaEndDateValue)
          item.finalDate = `${item.formattedStartDate} - ${item.formattedEndDate}`
          return item.finalDate
        }
      })
    },
  },
}
</script>
<template>
  <div id="tableView">
    <b-container class="gx-0">
      <b-row class="gx-0">
        <b-col class="gx-0">
          <div id="assignedDqas" class="tableContainer">
            <h6 class="font-weight-bold pt-2 px-2 pb-0 mb-0 sectionHeader">DQAs</h6>
            <TableGenericList :fields="fields" :records="dqaInfo"/>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<style scoped>
    .tableContainer{
        background: #95c8fb;
    }
</style>
